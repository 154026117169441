import LoginForm from '~/components/forms/account/login-form/login-form'
import Landing from '~/components/landing/landing'

export default function Login() {
  return (
    <Landing>
      <LoginForm />
    </Landing>
  )
}

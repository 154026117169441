import { Component } from 'solid-js'
import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import styles from '~/components/forms/styles/simple-form.module.scss'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { loginUser } from '~/services/database/users'
import { LoginSchema, LoginFormType } from '~/components/forms/account/login-form/login-form.interfaces'
import { A, useNavigate } from '@solidjs/router'
import { reportError } from '~/services/errors'
import { translations } from '~/translations'

const LoginForm: Component = () => {
  const navigate = useNavigate()
  const [form, { Form, Field }] = createForm<LoginFormType>({
    validate: valiForm(LoginSchema)
  })

  const onSubmit: SubmitHandler<LoginFormType> = async (values) => {
    const result = await loginUser({
      email: values.email,
      password: values.password
    })
    if (result.error) {
      const message = `
        ${result.error.name}\n
        ${result.error.message}\n
      `
      reportError(result.error)
      alert(message)
    } else {
      navigate('/')
    }
  }

  return (
    <div class={styles.container}>
      <div class={styles.title}>
        {translations().login.title}
      </div>
      <Form onSubmit={onSubmit} class={styles.form}>
        <Field name="email">
          {(field, props) => (
            <TextField
              label={translations().login.email}
              type='email'
              placeholder={translations().login.emailPlaceholder}
              autocomplete='email'
              {...field}
              {...props}
            />
          )}
        </Field>
        <Field name="password">
          {(field, props) => (
            <TextField
              label={translations().login.password}
              type='password'
              autocomplete='current-password'
              {...field}
              {...props}
            />
          )}
        </Field>
        <Button
          type='submit'
          style='lagoon'
          isLoading={form.submitting}
        >
          {translations().login.login}
        </Button>
      </Form>
      <div class={styles.auxLinks}>
        <A href='/account/register' class={styles.link}>
          {translations().login.register}
        </A>
        <A href='/account/request-password-reset' class={styles.link}>
          {translations().login.forgotPassword}
        </A>
      </div>
    </div>
  )
}

export default LoginForm
